import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`MIP0c13: Subproposals Template for Core Personnel Offboarding`}</h1>
    <h2>{`Preamble`}</h2>
    <pre><code parentName="pre" {...{}}>{`MIP0c13-SP#: #
Author(s):
Contributors: 
Status:
Date Proposed: <yyyy-mm-dd>
Date Removed: <yyyy-mm-dd>
---
Core Personnel Role: MIP Editor or Governance Facilitator
Core Personnel to be removed:
`}</code></pre>
    <h2>{`Removal Application and Supporting Evidence`}</h2>
    <h3>{`Motivation`}</h3>
    <ul>
      <li parentName="ul">{`The explanation behind the removal of the person from the role listed above.`}</li>
    </ul>
    <h3>{`Relevant Information`}</h3>
    <ul>
      <li parentName="ul">{`Links to evidence further backing the motivation behind the removal of the person from the role listed above.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      